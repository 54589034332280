import { defineMessages, useIntl } from 'react-intl';

import { type GA4FilterListType } from '@jsmdg/tracking';
import { SidePanel, SlidePanelDirection } from '@jsmdg/yoshi';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type Filter, type Sorting as SortingType } from '../../../../shared/types/search';
import { type SearchReducerActionType } from '../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../types/searchReducer';
import { FilterSidePanelBody, FilterSidePanelFooter, FilterSidePanelHeader } from '.';

type FilterSidePanelProps = {
    readonly filter: Filter;
    readonly isFilterPanelOpen: boolean;
    readonly sorting?: SortingType;
    readonly hasFilterChanged: boolean;
    readonly isInitialLocation: boolean;
    readonly isInitialPrice: boolean;
    readonly showLocationFilter: boolean;
    readonly shouldReset: boolean;
    readonly onFilterChange: (type: SearchReducerActionType, value?: SearchReducerValue) => void;
    readonly onSortingChange: (type: SearchReducerActionType, value?: SearchReducerValue) => void;
    readonly closeFilterPanel: () => void;
    readonly resetFilter: () => void;
    readonly locale: string;
    readonly itemCount: number;
    readonly geoLocationError?: GeolocationPositionError;
    readonly facets?: FacetFilter[];
    readonly paginationLimit?: number;
    readonly listType: GA4FilterListType;
};

const messages = defineMessages({
    a11yCloseText: {
        defaultMessage: 'Schließen',
    },
});

const FilterSidePanel = ({
    closeFilterPanel,
    facets,
    filter,
    geoLocationError,
    hasFilterChanged,
    isFilterPanelOpen,
    isInitialLocation,
    isInitialPrice,
    itemCount,
    listType,
    locale,
    onFilterChange,
    onSortingChange,
    paginationLimit,
    resetFilter,
    shouldReset,
    showLocationFilter,
    sorting,
}: FilterSidePanelProps): JSX.Element => {
    const intl = useIntl();

    const onSortingChangeHandler = (type: SearchReducerActionType, value?: SearchReducerValue) => {
        onSortingChange(type, value);
        closeFilterPanel();
    };

    return (
        <SidePanel
            direction={SlidePanelDirection.Right}
            fullWidth
            open={isFilterPanelOpen}
            onRequestClose={closeFilterPanel}
            headerElements={<FilterSidePanelHeader />}
            headerClassname="py-1-5x px-2x"
            boxClassname="p-0"
            a11yCloseText={intl.formatMessage(messages.a11yCloseText)}
        >
            <>
                <FilterSidePanelBody
                    sorting={sorting}
                    filter={filter}
                    showLocationFilter={showLocationFilter}
                    shouldReset={shouldReset}
                    isInitialLocation={isInitialLocation}
                    isInitialPrice={isInitialPrice}
                    onFilterChange={onFilterChange}
                    onSortingChange={onSortingChangeHandler}
                    geoLocationError={geoLocationError}
                    facets={facets}
                    paginationLimit={paginationLimit}
                />
                <FilterSidePanelFooter
                    filter={filter}
                    locale={locale}
                    itemCount={itemCount}
                    hasFilterChanged={hasFilterChanged}
                    resetFilter={resetFilter}
                    closeFilterPanel={closeFilterPanel}
                    listType={listType}
                />
            </>
        </SidePanel>
    );
};

// eslint-disable-next-line import/no-default-export
export default FilterSidePanel;
